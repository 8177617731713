import React, { useState, useEffect } from 'react'
import { ArrowRight, Heart } from 'react-feather'
import chroma from 'chroma-js'
import parseCombos from '../lib/parseCombos'
import { randomInt } from '../lib/util'
import { sample } from 'lodash'
import { Sparklines, SparklinesLine, SparklinesBars } from 'react-sparklines'

import Icon from 'react-geomicons'
import Input from '../elements/Input'
import Textarea from '../elements/Textarea'
import Span from '../elements/Span'
import Div from '../elements/Div'
import H4 from '../elements/H4'
import P from '../elements/P'
import Flex from '../components/Flex'
import Lock from '../components/Lock'
import Color from '../components/controls/Color'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import SectionBorder from '../components/SectionBorder'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomTheme from '../lib/generate/theme-ui'
import { db } from '../lib/client'
import { flatten, toGoogleFontUrl } from '../lib/util'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const ALPHABET = `
abcdefghijklmnopqrstuvwxyz
`
const CHARS = `
1234567890-~!@#$%^&*()_
`

const DEFAULT_TITLE_CONTENT = `
Everyone Is Genuinely Confused About Why This Heading Is Making People Freak Out
`

const DEFAULT_BODY_CONTENT = `
Augue eget arcu dictum varius. Dignissim convallis aenean et tortor at
risus viverra adipiscing at. Est ante in nibh mauris cursus mattis
molestie. Condimentum mattis pellentesque id nibh tortor id.
`

const googleFontPairingToTheme = pairing => {
  const colorScale = chroma
    .scale([
      '#000',
      pairing.colors.first,
      chroma.random(),
      pairing.colors.second,
      '#fff'
    ])
    .padding([-50 / 100, -50 / 100])
    .mode(sample(['lab', 'lch', 'lrgb', 'rgb', 'hsl']))
    .correctLightness()
    .colors(48)

  const combos = parseCombos(colorScale)

  const colorCombo = sample(combos)

  return {
    fonts: {
      body: pairing.body.fontFamily,
      heading: pairing.heading.fontFamily
    },
    lineHeights: {
      body: pairing.body.lineHeight,
      heading: pairing.heading.lineHeight
    },
    fontWeights: {
      body: pairing.body.fontWeight,
      heading: pairing.heading.fontWeight
    },
    colors: {
      first: pairing.colors.first,
      second: pairing.colors.second,
      palette: colorScale,
      bg: '#fff',
      text: colorCombo.hex,
      background: colorCombo.pairing,
      body: colorCombo.hex,
      primary: colorScale[40],
      primaryBorder: colorScale[38],
      secondary: colorScale[10],
      secondaryBorder: colorScale[8],
      muted: colorScale[44]
    },
    fontSizes: pairing.fontSizes,
    spacing: pairing.spacing,
    spacing0: pairing.spacing[0],
    spacing1: pairing.spacing[1],
    spacing2: pairing.spacing[2],
    spacing3: pairing.spacing[3],
    spacing4: pairing.spacing[4],
    spacing5: pairing.spacing[5],
    spacing6: pairing.spacing[6],
    spacing7: pairing.spacing[7],
    spacing8: pairing.spacing[8],
    fontSize0: pairing.fontSizes[0],
    fontSize1: pairing.fontSizes[1],
    fontSize2: pairing.fontSizes[2],
    fontSize3: pairing.fontSizes[3],
    fontSize4: pairing.fontSizes[4],
    fontSize5: pairing.fontSizes[5],
    fontSize6: pairing.fontSizes[6],
    fontSize7: pairing.fontSizes[7],
    fontSize8: pairing.fontSizes[8]
  }
}

const randomFontPairing = () => {
  const themeUI = randomTheme()

  return {
    ...googleFontPairingToTheme(themeUI),
    metadata: {
      fontLinkHref: toGoogleFontUrl(themeUI)
    }
  }
}

const Main = ({ fontPairing, title, body }) => {
  const data = [
    {
      id: 'python',
      label: 'python',
      value: 469,
      color: 'hsl(267, 70%, 50%)'
    },
    {
      id: 'php',
      label: 'php',
      value: 390,
      color: 'hsl(306, 70%, 50%)'
    },
    {
      id: 'c',
      label: 'c',
      value: 387,
      color: 'hsl(251, 70%, 50%)'
    },
    {
      id: 'java',
      label: 'java',
      value: 509,
      color: 'hsl(282, 70%, 50%)'
    },
    {
      id: 'javascript',
      label: 'javascript',
      value: 235,
      color: 'hsl(262, 70%, 50%)'
    }
  ]
  const borderInt = randomInt(0, 9)

  return (
    <Div bg={fontPairing['colors.bg']} css={{ minHeight: '100vh' }}>
      <link rel="stylesheet" href={fontPairing['metadata.fontLinkHref']} />
      <Flex
        pt={6}
        alignItems="flex-start"
        flexWrap="wrap"
        justifyContent={['center']}
        css={{
          fontFamily: fontPairing['fonts.body']
        }}
      >
        <Div
          mx="auto"
          maxWidth="38em"
          fontSize={fontPairing['fontSize2'] + 'px'}
        >
          <div
            css={{
              color: fontPairing['colors.text'],
              background: fontPairing['colors.background'],
              borderTop:
                borderInt > 4
                  ? '4px solid ' + fontPairing['colors.primary']
                  : 'none',
              padding: 128
            }}
          >
            <h2
              css={{
                marginTop: 0,
                marginBottom: '.5em',
                paddingBottom: '.5em',
                fontSize: fontPairing['fontSize3'] + 'px',
                fontFamily: fontPairing['fonts.heading'],
                fontWeight: fontPairing['fontWeights.heading'],
                lineHeight: fontPairing['lineHeights.heading'],
                borderBottom:
                  borderInt < 5
                    ? '1px solid ' + fontPairing['colors.primary']
                    : 'none'
              }}
            >
              {title}
            </h2>
            {body.map((str, i) => (
              <P
                key={i}
                css={{
                  marginTop: 0,
                  fontSize: fontPairing['fontSize2'] + 'px',
                  fontFamily: fontPairing['fonts.body'],
                  lineHeight: fontPairing['lineHeights.body']
                }}
              >
                {str}
              </P>
            ))}
            <P
              css={{
                fontSize: fontPairing['fontSize1'] + 'px',
                fontFamily: fontPairing['fonts.body'],
                lineHeight: fontPairing['lineHeights.body'],
                color: fontPairing['colors.primary']
              }}
            >
              Read More
            </P>
          </div>
          <section
            css={{
              marginTop: 32,
              fontSize: fontPairing['fontSize1'],
              display: 'flex',
              flexWrap: 'none'
            }}
          >
            <div
              css={{
                borderRadius: 5,
                marginBottom: 8,
                marginRight: 16,
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,
                display: 'inline-flex',
                alignItems: 'center',
                background: fontPairing['colors.background'],
                color: fontPairing['colors.text'],
                border: '1px solid ' + fontPairing['colors.background']
              }}
            >
              <span css={{ paddingRight: 4 }}>Click Here</span>{' '}
              <Icon name="chevronRight" />
            </div>
            <div
              css={{
                borderRadius: 5,
                marginBottom: 8,
                marginRight: 16,
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,
                display: 'inline-flex',
                alignItems: 'center',
                color: fontPairing['colors.background'],
                background: fontPairing['colors.text'],
                border: '1px solid ' + fontPairing['colors.text']
              }}
            >
              <span css={{ paddingRight: 4 }}>Click Here</span>{' '}
              <Icon name="chevronRight" />
            </div>
            <div
              css={{
                borderRadius: 5,
                marginBottom: 8,
                marginRight: 16,
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,
                display: 'inline-flex',
                alignItems: 'center',
                color: fontPairing['colors.background'],
                border: '1px solid'
              }}
            >
              <span css={{ paddingRight: 4 }}>Click Here</span>{' '}
              <Icon name="chevronRight" />
            </div>
            <div
              css={{
                borderRadius: 5,
                marginBottom: 8,
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,
                display: 'inline-flex',
                alignItems: 'center',
                color: fontPairing['colors.text'],
                border: '1px solid'
              }}
            >
              <span css={{ paddingRight: 4 }}>Click Here</span>{' '}
              <Icon name="chevronRight" />
            </div>
          </section>
          <section css={{ marginTop: 32, fontSize: fontPairing['fontSize0'] }}>
            <div
              css={{
                borderRadius: 9999,
                marginBottom: 8,
                marginRight: 16,
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8,
                display: 'inline-flex',
                alignItems: 'center',
                background: fontPairing['colors.background'],
                color: fontPairing['colors.text']
              }}
            >
              <Icon name="lock" />
              <span css={{ paddingLeft: 4 }}>Badge</span>{' '}
            </div>
            <div
              css={{
                borderRadius: 9999,
                marginBottom: 8,
                marginRight: 16,
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8,
                display: 'inline-flex',
                alignItems: 'center',
                color: fontPairing['colors.background'],
                background: fontPairing['colors.text']
              }}
            >
              <Icon name="geolocation" />
              <span css={{ paddingLeft: 4 }}>Badge</span>{' '}
            </div>
            <div
              css={{
                borderRadius: 9999,
                marginBottom: 8,
                marginRight: 16,
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8,
                display: 'inline-flex',
                alignItems: 'center',
                color: fontPairing['colors.background'],
                border: '1px solid'
              }}
            >
              <Icon name="check" />
              <span css={{ paddingLeft: 4 }}>Badge</span>{' '}
            </div>
            <div
              css={{
                borderRadius: 9999,
                marginBottom: 8,
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8,
                display: 'inline-flex',
                alignItems: 'center',
                color: fontPairing['colors.text'],
                border: '1px solid'
              }}
            >
              <Icon name="heart" />
              <span css={{ paddingLeft: 4 }}>Badge</span>{' '}
            </div>
          </section>
          <section css={{ marginTop: 32 }}>
            <div
              css={{
                borderRadius: 9999,
                height: 12,
                background: fontPairing['colors.muted'],
                overflow: 'hidden',
                display: 'flex'
              }}
            >
              <div
                css={{
                  height: 12,
                  background: fontPairing['colors.secondary'],
                  width: '25%'
                }}
              ></div>
            </div>
            <div
              css={{
                marginTop: 8,
                borderRadius: 9999,
                height: 12,
                background: fontPairing['colors.muted'],
                overflow: 'hidden',
                display: 'flex'
              }}
            >
              <div
                css={{
                  height: 12,
                  background: fontPairing['colors.secondary'],
                  width: '75%'
                }}
              ></div>
            </div>
          </section>
          <section
            css={{
              marginTop: 32,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div css={{ width: '33.33%', paddingRight: 16 }}>
              <Sparklines data={[5, 6, 7, 3, 8, 5, 9, 8, 7, 5, 7, 6, 4, 1]}>
                <SparklinesBars
                  style={{
                    stroke: 'transparent',
                    strokeWidth: '1',
                    fill: fontPairing['colors.secondary']
                  }}
                />
              </Sparklines>
            </div>
            <div css={{ width: '33.33%', paddingLeft: 8, paddingRight: 8 }}>
              <Sparklines data={[2, 4, 5, 1, 9, 9, 20, 1, 5, 9, 13, 18, 14, 1]}>
                <SparklinesLine
                  color={fontPairing['colors.secondary']}
                  strokeWidth={2}
                />
              </Sparklines>
            </div>
            <div css={{ width: '33.33%', paddingLeft: 16 }}>
              <Sparklines
                data={[10, 5, 9, 4, 8, 9, 11, 13, 17, 9, 13, 14, 14, 13]}
              >
                <SparklinesLine
                  color={fontPairing['colors.text']}
                  strokeWidth={2}
                />
              </Sparklines>
            </div>
          </section>
          <section css={{ display: 'flex', flexWrap: 'none', paddingTop: 64 }}>
            <div css={{ paddingRight: 8 }}>
              <article
                css={{
                  border: '1px solid ' + fontPairing['colors.primary'],
                  borderRadius: 7,
                  overflow: 'hidden',
                  padding: 4
                }}
              >
                <img
                  src="http://dc28c2r6oodom.cloudfront.net/p/h/175.jpg"
                  css={{ borderRadius: 5, width: '100%', display: 'block' }}
                />
              </article>
            </div>
            <div css={{ paddingLeft: 8 }}>
              <article
                css={{
                  border: '1px solid ' + fontPairing['colors.secondary'],
                  borderRadius: 7,
                  overflow: 'hidden',
                  padding: 4
                }}
              >
                <img
                  src="http://dc28c2r6oodom.cloudfront.net/p/h/176.jpg"
                  css={{ borderRadius: 5, width: '100%', display: 'block' }}
                />
              </article>
            </div>
          </section>
        </Div>
      </Flex>
    </Div>
  )
}

const Sidebar = ({
  onChange,
  onContentChange,
  content,
  value,
  pins,
  onPin
}) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  return (
    <>
      <SidebarHeading>Theme UI</SidebarHeading>
      <h5 css={{ marginBottom: 8 }}>Heading</h5>
      <Flex mr={-3} py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins['fonts.heading']}
          onClick={() => onPin('fonts.heading', value['fonts.heading'])}
        />
        <Label>Font</Label>
        <Input
          value={value['fonts.heading']}
          onChange={changeValue('fonts.heading')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins['lineHeights.heading']}
          onClick={() =>
            onPin('lineHeights.heading', value['lineHeights.heading'])
          }
        />
        <Label>Line height</Label>
        <Input
          min={-1}
          max={2}
          step={0.05}
          width={1}
          mr={2}
          type="range"
          value={value['lineHeights.heading']}
          onChange={changeValue('lineHeights.heading')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value['lineHeights.heading']}
        />
      </Flex>
      <Div py={1} display="none">
        <Label mb={1}>Content</Label>
        <Textarea
          p={2}
          width={1}
          height={48}
          fontSize={0}
          border="1px solid rgba(0,0,0,.25)"
          onChange={e => onContentChange('title', e.target.value)}
          value={content.title}
        ></Textarea>
      </Div>
      <SectionBorder mt={3} />
      <h5 css={{ marginBottom: 8 }}>Text</h5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins['fonts.body']}
          onClick={() => onPin('fonts.body', value['fonts.body'])}
        />
        <Label>Font</Label>
        <Input
          value={value['fonts.body']}
          onChange={changeValue('fonts.body')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins['lineHeights.body']}
          onClick={() => onPin('lineHeights.body', value['lineHeights.body'])}
        />
        <Label>Line height</Label>
        <Input
          min={-1}
          max={2}
          step={0.05}
          width={1}
          mr={2}
          type="range"
          value={value['lineHeights.body']}
          onChange={changeValue('lineHeights.body')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value['lineHeights.body']}
        />
      </Flex>
      <SectionBorder mt={3} />
      <Div py={1} display="none">
        <Label mb={1}>Content</Label>
        <Textarea
          p={2}
          height={48}
          width={1}
          fontSize={0}
          border="1px solid rgba(0,0,0,.25)"
          onChange={e => onContentChange('body', e.target.value)}
        >
          {content.body}
        </Textarea>
      </Div>
      <h5 css={{ marginBottom: 8 }}>Colors</h5>
      <Color
        label="Canvas"
        value={value['colors.bg']}
        onChange={changeValue('colors.bg')}
        active={pins['colors.bg']}
        onLock={() => onPin('colors.bg', value['colors.bg'])}
        width={128}
      />
      <Color
        label="Background"
        value={value['colors.background']}
        onChange={changeValue('colors.background')}
        active={pins['colors.background']}
        onLock={() => onPin('colors.background', value['colors.background'])}
        width={128}
      />
      <Color
        label="Text"
        value={value['colors.text']}
        onChange={changeValue('colors.text')}
        active={pins['colors.text']}
        onLock={() => onPin('colors.text', value['colors.text'])}
        width={128}
      />
      <Color
        label="Primary"
        value={value['colors.primary']}
        onChange={changeValue('colors.primary')}
        active={pins['colors.primary']}
        onLock={() => onPin('colors.primary', value['colors.primary'])}
        width={128}
      />
      <Color
        label="Secondary"
        value={value['colors.secondary']}
        onChange={changeValue('colors.secondary')}
        active={pins['colors.primary']}
        onLock={() => onPin('colors.secondary', value['colors.secondary'])}
        width={128}
      />
      <Color
        label="Muted"
        value={value['colors.muted']}
        onChange={changeValue('colors.muted')}
        active={pins['colors.primary']}
        onLock={() => onPin('colors.muted', value['colors.muted'])}
        width={128}
      />
      <SectionBorder mt={3} />
      <h5 css={{ marginBottom: 8 }}>Spacing</h5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing1}
          onClick={() => onPin('spacing0', value.spacing0)}
        />
        <Label minWidth={16} width={16}>
          0
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing0)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('spacing0')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.spacing0)}
          type="number"
          onChange={changeValue('spacing0')}
        />
        <Span width="auto" textAlign="left" fontSize={0} children={'px'} />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing1}
          onClick={() => onPin('spacing1', value.spacing1)}
        />
        <Label minWidth={16} width={16}>
          1
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing1)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('spacing1')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.spacing1)}
          type="number"
          onChange={changeValue('spacing1')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing2}
          onClick={() => onPin('spacing2', value.spacing2)}
        />
        <Label minWidth={16} width={16}>
          2
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing2)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('spacing2')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.spacing2)}
          type="number"
          onChange={changeValue('spacing2')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing3}
          onClick={() => onPin('spacing3', value.spacing3)}
        />
        <Label minWidth={16} width={16}>
          3
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing3)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('spacing3')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.spacing3)}
          type="number"
          onChange={changeValue('spacing3')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing4}
          onClick={() => onPin('spacing4', value.spacing4)}
        />
        <Label minWidth={16} width={16}>
          4
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing4)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('spacing4')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.spacing4)}
          type="number"
          onChange={changeValue('spacing4')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing5}
          onClick={() => onPin('spacing5', value.spacing5)}
        />
        <Label minWidth={16} width={16}>
          5
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing5)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('spacing5')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.spacing5)}
          type="number"
          onChange={changeValue('spacing5')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing6}
          onClick={() => onPin('spacing6', value.spacing6)}
        />
        <Label minWidth={16} width={16}>
          6
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing6)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('spacing6')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.spacing6)}
          type="number"
          onChange={changeValue('spacing6')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing7}
          onClick={() => onPin('spacing7', value.spacing7)}
        />
        <Label minWidth={16} width={16}>
          7
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing7)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('spacing7')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.spacing7)}
          type="number"
          onChange={changeValue('spacing7')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing8}
          onClick={() => onPin('spacing8', value.spacing8)}
        />
        <Label minWidth={16} width={16}>
          8
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing8)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('spacing8')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.spacing8)}
          type="number"
          onChange={changeValue('spacing8')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <SectionBorder mt={3} />
      <h5 css={{ marginBottom: 8 }}>Type Scale</h5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize0}
          onClick={() => onPin('fontSize0', value.fontSize0)}
        />
        <Label minWidth={16} width={16}>
          0
        </Label>
        <Input
          min={0}
          max={1024}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSize0)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('fontSize0')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.fontSize0)}
          type="number"
          onChange={changeValue('fontSize0')}
        />
        <Span width="auto" textAlign="left" fontSize={0} children={'px'} />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize1}
          onClick={() => onPin('fontSize1', value.fontSize1)}
        />
        <Label minWidth={16} width={16}>
          1
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.fontSize1)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('fontSize1')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.fontSize1)}
          type="number"
          onChange={changeValue('fontSize1')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize2}
          onClick={() => onPin('fontSize2', value.fontSize2)}
        />
        <Label minWidth={16} width={16}>
          2
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.fontSize2)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('fontSize2')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.fontSize2)}
          type="number"
          onChange={changeValue('fontSize2')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize3}
          onClick={() => onPin('fontSize3', value.fontSize3)}
        />
        <Label minWidth={16} width={16}>
          3
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.fontSize3)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('fontSize3')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.fontSize3)}
          type="number"
          onChange={changeValue('fontSize3')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize4}
          onClick={() => onPin('fontSize4', value.fontSize4)}
        />
        <Label minWidth={16} width={16}>
          4
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.fontSize4)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('fontSize4')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.fontSize4)}
          type="number"
          onChange={changeValue('fontSize4')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize5}
          onClick={() => onPin('fontSize5', value.fontSize5)}
        />
        <Label minWidth={16} width={16}>
          5
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.fontSize5)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('fontSize5')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.fontSize5)}
          type="number"
          onChange={changeValue('fontSize5')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize6}
          onClick={() => onPin('fontSize6', value.fontSize6)}
        />
        <Label minWidth={16} width={16}>
          6
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.fontSize6)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('fontSize6')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.fontSize6)}
          type="number"
          onChange={changeValue('fontSize6')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize7}
          onClick={() => onPin('fontSize7', value.fontSize7)}
        />
        <Label minWidth={16} width={16}>
          7
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.fontSize7)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('fontSize7')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.fontSize7)}
          type="number"
          onChange={changeValue('fontSize7')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize8}
          onClick={() => onPin('fontSize8', value.fontSize8)}
        />
        <Label minWidth={16} width={16}>
          8
        </Label>
        <Input
          min={0}
          max={1024}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.fontSize8)}
          type="range"
          style={{ flexGrow: 1 }}
          onChange={changeValue('fontSize8')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.fontSize8)}
          type="number"
          onChange={changeValue('fontSize8')}
        />
        <Span
          width="32px"
          textAlign="left"
          ml="auto"
          fontSize={0}
          children={'px'}
        />
      </Flex>
      <SectionBorder my={4} />
      <h5 css={{ marginBottom: 8 }}>Js</h5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        fontSize={0}
        readOnly
        value={`{
  space: [
    ${value.spacing0},
    ${value.spacing1},
    ${value.spacing2},
    ${value.spacing3},
    ${value.spacing4},
    ${value.spacing5},
    ${value.spacing6},
    ${value.spacing7},
    ${value.spacing8},
  ],
  fonts: {
    body: ${value['fonts.body']},
    heading: ${value['fonts.heading']},
    monospace: 'Menlo, monospace'
  },
  fontSizes: [
    ${value.fontSize0},
    ${value.fontSize1},
    ${value.fontSize2},
    ${value.fontSize3},
    ${value.fontSize4},
    ${value.fontSize5},
    ${value.fontSize6},
    ${value.fontSize7},
    ${value.fontSize8},
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: ${value['lineHeights.body']},
    heading: ${value['lineHeights.heading']}
  },
  colors: {
    text: ${value['colors.text']},
    background: ${value['colors.background']},
    primary: ${value['colors.primary']},
    secondary: ${value['colors.secondary']},
    muted: ${value['colors.muted']}
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body'
    },
    a: {
      color: 'primary'
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%'
    }
  }
}
`}
      />
      <p css={{ fontSize: 14 }}>
        For use with{' '}
        <a
          href="https://theme-ui.com"
          css={{ textDecoration: 'none', color: '#1f1f1b' }}
        >
          theme-ui.com
        </a>
      </p>
    </>
  )
}

export default () => {
  const [fontPairing, setFontPairing] = useState(randomFontPairing())
  const [pins, setPins] = useState({})
  const [content, setContent] = useState({
    title: DEFAULT_TITLE_CONTENT.trim(),
    body: DEFAULT_BODY_CONTENT.trim()
  })

  const flattenedFontPairing = flatten(fontPairing)
  const fullFontPairing = flatten({ ...flattenedFontPairing, ...pins })

  const upvote = () => {
    db.collection('components')
      .doc('fontPairings')
      .collection('votes')
      .add({
        up: true,
        data: fullFontPairing,
        metadata: { pins }
      })

    setFontPairing(randomFontPairing())
  }
  const skip = () => {
    setFontPairing(randomFontPairing())
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        <Main
          fontPairing={fullFontPairing}
          title={content.title.trim()}
          body={content.body.trim().split(/\n{2,}/g)}
        />
      }
      sidebar={
        <Sidebar
          value={fullFontPairing}
          pins={pins}
          content={content}
          onContentChange={(key, value) => {
            setContent({
              ...content,
              [key]: value
            })
          }}
          onChange={(key, value) => {
            setFontPairing({
              ...fontPairing,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <Flex>
            <VoteButton width="100%" onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
