const sample = require('lodash.sample')
const flatten = require('lodash.flatten')
const isNumber = require('is-number')
const randomHex = require('random-hex-color')
const chroma = require('chroma-js')

const { randomInt, randomBool } = require('../util')
const lineHeight = require('./line-height')
const fontSizes = require('./font-sizes')

const googleFonts = require('../../data/fonts/google-fonts.json')

const googleFontPairs = require('../../data/fonts/google-font-pairs.json')
const typewolfFontPairs = require('../../data/fonts/typewolf-font-pairs.json')

const sansFonts = require('../../data/fonts/google-curated-sans.json')
const serifFonts = require('../../data/fonts/google-curated-serif.json')
const monoFonts = require('../../data/fonts/google-curated-mono.json')
const typewolfFonts = require('../../data/fonts/typewolf-fonts.json')

// We purposefully allow for duplicated fonts because it will increase
// their likelihood of being sampled. Fonts that appear frequently are
// more likely to look nice with others. We might want to introduce
// data/fonts/google-fonts so that all fonts are represented, but that
// might introduce a bit too much chaos. A lot of fonts are pretty bad.
const allFonts = [
  ...sansFonts,
  ...serifFonts,
  ...monoFonts,
  ...typewolfFonts,
  ...flatten(googleFontPairs),
  ...flatten(typewolfFontPairs)
]

// Use a weighting system to determine lists to query from. When we
// land on sans/serif/mono fonts we also randomize the order between
// heading and body for a bit more entropy. We select the other  from
// allFonts to allow for more possible outcomes.
const WEIGHTS = {
  googleFontPairs: 1,
  typewolfFontPairs: 6,
  sansFonts: 7,
  serifFonts: 8,
  monoFonts: 9
}

// Not all font weights are available for all fonts. We also don't
// care about italic variants right now so we filter those out.
const availableFontWeights = fontFamily => {
  const font = googleFonts.find(f => f.family === fontFamily)

  if (!font) {
    return []
  }

  return font.variants.filter(isNumber)
}

// Turn a google font name into a full font stack.
const fullFontFamily = fontFamily => {
  const font = googleFonts.find(f => f.family === fontFamily) || {}

  return [
    font.family ? fontFamily : 'system-ui',
    font.category === 'sans-serif' ? 'system-ui' : null,
    font.category || 'sans-serif'
  ]
    .filter(Boolean)
    .join(', ')
}

// Turn two google font names into a full CSS object.
// mrmrs edited this code and added a bunch of messy color stuff and other things
//
const fullDefinition = pair => {
  const fontSizes = [
    [12, 14, 16, 24, 32, 48, 64, 96, 128],
    [12, 14, 16, 24, 32, 48, 64, 96, 128],
    [12, 14, 16, 24, 32, 48, 64, 96, 128],
    [12, 14, 16, 24, 32, 48, 64, 96, 128],
    [12, 14, 16, 24, 32, 48, 64, 96, 128],
    [12, 14, 16, 24, 32, 48, 64, 96, 128],
    [12, 14, 16, 24, 32, 48, 64, 96, 128],
    [12, 14, 16, 24, 32, 48, 64, 96, 128],
    [12, 16, 24, 32, 48, 64, 96, 128, 192],
    [12, 16, 24, 32, 48, 64, 96, 128, 192],
    [12, 16, 24, 32, 48, 64, 96, 128, 192],
    [12, 16, 24, 32, 48, 64, 96, 128, 192],
    [12, 16, 24, 36, 54, 81, 122, 182, 264],
    [12, 15, 18, 22, 26, 31, 37, 45, 54],
    [12, 15, 19, 23, 29, 37, 46, 57, 72],
    [15, 20, 27, 36, 47, 63, 84, 112, 149],
    [15, 19, 23, 29, 37, 46, 57, 72, 89],
    [15, 18, 22, 26, 31, 37, 45, 54, 65],
    [15, 21, 30, 42, 60, 85, 120, 170, 240],
    [18, 22, 26, 31, 37, 45, 54, 65, 77],
    [13, 16, 20, 25, 31, 39, 49, 61, 75],
    [13, 16, 19, 23, 28, 33, 40, 48, 57],
    [14, 16, 18, 20, 23, 26, 29, 32, 36],
    [10, 16, 26, 42, 68, 110, 177, 287, 465],
    [11, 16, 23, 32, 45, 64, 90, 128, 181],
    [11, 12, 13, 14, 18, 24, 36, 48, 64],
    [12, 13, 14, 18, 24, 36, 48, 64, 72],
    [13, 14, 18, 24, 36, 48, 64, 72, 96],
    [14, 18, 24, 36, 48, 64, 72, 96, 144],
    [14, 16, 24, 36, 48, 64, 72, 96, 144],
    [18, 24, 36, 48, 64, 72, 96, 144, 288]
  ]

  const spacings = [
    [0, 4, 8, 16, 32, 64, 128, 256, 512],
    [0, 4, 8, 16, 24, 32, 48, 56, 64],
    [0, 5, 10, 20, 40, 80, 160, 320, 640],
    [0, 2, 4, 8, 16, 32, 64, 128, 256],
    [0, 4, 8, 16, 24, 32, 48, 64, 96],
    [0, 4, 8, 12, 20, 32, 52, 84, 128],
    [0, 5, 10, 15, 25, 30, 40, 50, 60],
    [0, 4, 8, 16, 24, 32, 40, 52, 64],
    [0, 2, 4, 8, 12, 16, 20, 24, 28],
    [0, 2, 4, 8, 12, 16, 20, 24, 28]
  ]

  const colors = {
    first: randomHex(),
    second: randomHex()
  }

  const heading = {
    name: pair[0],
    fontFamily: fullFontFamily(pair[0]),
    fontWeight: sample(availableFontWeights(pair[0])) || 'normal',
    lineHeight: randomInt(120, 150) / 100
  }

  const bodyFontWeight = sample(availableFontWeights(pair[1]))

  const body = {
    name: pair[1],
    fontFamily: fullFontFamily(pair[1]),
    fontWeight: 400,
    lineHeight: randomInt(140, 200) / 100
  }

  return {
    colors,
    heading,
    body,
    fontSizes: sample(fontSizes),
    spacing: sample(spacings)
  }
}

const getPair = () => {
  const randInt = randomInt(0, 12)

  if (randInt <= WEIGHTS.googleFontPairs) {
    return sample(googleFontPairs)
  } else if (randInt <= WEIGHTS.typewolfFontPairs) {
    return sample(typewolfFontPairs)
  } else if (randInt <= WEIGHTS.sansFonts) {
    const pair = [sample(sansFonts), sample(allFonts)]

    return randomBool() ? pair : pair.reverse()
  } else if (randInt <= WEIGHTS.serifFonts) {
    const pair = [sample(serifFonts), sample(allFonts)]

    return randomBool() ? pair : pair.reverse()
  } else if (randInt <= WEIGHTS.monoFonts) {
    const pair = [sample(monoFonts), sample(allFonts)]

    return randomBool() ? pair : pair.reverse()
  }

  return [sample(allFonts), sample(allFonts)]
}

module.exports = () => fullDefinition(getPair())
